<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <Loader v-if="loading"></Loader>
      <img-logo />

      <!-- Forgot password-->
      <b-col lg="6" class="recover-password">
        <h2 class="recover-password__title">Esqueceu sua Senha?</h2>
        <p class="recover-password__paragraph">
          Sem problemas, digite o seu e-mail abaixo para resetar sua senha.
        </p>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm">
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email">
                <b-form-input
                  id="forgot-password-email"
                  v-model="reset.emailAddress"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="email@exemplo.com.br" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              class="recover-password__button">
              Continuar
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link
            :to="{ name: 'login' }"
            class="back">
            Voltar
          </b-link>
        </p>
      </b-col>

      <background-card />
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import BackgroundCard from './template/BackgroundCard.vue'
import ImgLogo from './template/ImgLogo.vue'
import Loader from '@/components/Loader.vue'
import toast from '@/mixins/toast'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BackgroundCard,
    ImgLogo,
    Loader,
  },

  mixins: [toast],

  data() {
    return {
      emailAddress: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      email,
      loading: false,
      reset: {
        emailAddress: null,
      },
      imgLogo: require('@/assets/images/logo/logo.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.recover()
        }
      })
    },
    async recover() {
      try {
        this.loading = true
        const response = await this.$http.post(
          '/api/services/app/Account/SendPasswordResetCode',
          this.reset, {
            timeout: 30000,
          },
        )
        if (response) {
          setTimeout(() => {     
            this.loading = false
            this.$router.push({ name: 'recover-password-succeed' })
          }, 1000)
        }
      } catch (error) {
        this.msgError(error.response.data.error.message)      
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
body {
  background-color: #fff !important;
}
a.back {
  color: #000;
  font-weight: 500;
}
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import './sass/_recover-password.scss';
</style>
